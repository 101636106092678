<template>
	<div id="notfound" class="notfound">
		<span class="notfound__logo">
			<svg viewBox="0 0 207 63">
				<use xlink:href="#logo--main" width="100%" height="100%" />
			</svg>
		</span>
		<div class="notfound__text">
			<span>We're sorry!</span> this page doesn't exist.
			<a href="https://promotion-group.ch" target="_blank">
				Visit our website
			</a>
		</div>
	</div>
</template>
<script>
export default {
	name: 'NotFound'
}
</script>
<style lang="scss">
.notfound {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	height: 90svh;
	&__logo {
		display: block;
		@include size(361px,110px);
		svg {
			display: block;
			@include size(100%,100%);
			fill: $color-white;
		}
	}
	&__text {
		display: block;
		width: 100%;
		font-size: 1.5rem;
		font-weight: 200;
		text-align: center;
		color: $color-white;
		margin-top: var(--supergutter);
		span {
			display: block;
			text-align: center;
			width: 100%;
			font-size: 2.3rem;
			font-family: $font-piazzolla;
			font-variation-settings: 'wght' 400;
			font-style: italic;
			margin-bottom: var(--gutter);
		}
	}

	a {
		display: block;
		width: max-content;
		background: $color-white;
		color: $color-black;
		padding: 12px 30px;
		margin: 60px auto 0;
	}
}
</style>
