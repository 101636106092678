<template>
	<div class="landing">
		<span class="landing__logo">
			<svg viewBox="0 0 207 63">
				<use xlink:href="#logo--main" width="100%" height="100%" />
			</svg>
		</span>
<!--		<section class="landing__dyk landing__block">-->
<!--			<span class="landing__dyk__title">-->
<!--				Sai cos'è una Digital Business Card?-->
<!--			</span>-->
<!--			<span class="landing__dyk__text">-->
<!--				È l'evoluzione dei tradizionali biglietti da visita. Al suo interno è possibile inserire tutte le proprie informazioni di contatto, facilitando lo scambio rapido e digitale dei recapiti lavorativi.-->
<!--			</span>-->
<!--		</section>-->
		<section class="landing__vcard landing__block">
			<span class="landing__vcard__title">
				Here you can create your Digital Business Card!
			</span>
			<figure class="landing__vcard__media">
				<img class="landing__vcard__media__image" src="@/assets/images/vcard.png" alt="" />
			</figure>
		</section>
		<section class="landing__ctas landing__block">
			<router-link :to="{ name: 'redeem.page', query: { id: code } }" class="btn filled">
				Start now
			</router-link>
			<!-- <router-link to="" class="simple-link">
				Altre informazioni
			</router-link> -->
		</section>
<!--		<span class="landing__logo small">-->
<!--			<svg viewBox="0 0 207 63">-->
<!--				<use xlink:href="#logo&#45;&#45;main" width="100%" height="100%" />-->
<!--			</svg>-->
<!--		</span>-->
	</div>
</template>
<script>
export default {
	name: 'Landing',
	props: {
		code: {
			type: String,
			required: true,
		}
	},
	data() {
		return {

		}
	},
}
</script>
<style lang="scss">
@import '~@/assets/scss/pages/landing';
</style>
