<template>
	<div id="app" class="app">
		<span class="app__bg">
			<img src="@/assets/images/bg.jpg" alt="" />
		</span>
		<Icons />
		<router-view/>
		<span class="app__credits">
			Powered by <a href="https://mwd.digital" target="_blank"><img src="@/assets/images/mwd.png" alt="" /></a>
		</span>
	</div>
</template>
<script>
import { EventBus } from '@/events_bus.js';

import Icons from '@/views/layouts/utils/Icons';

export default {
	name: 'App',
	components: {
		Icons,
	},
	data() {
		return {
			windowWidth: 0,
		}
	},
	metaInfo: {
		// if no subcomponents specify a metaInfo.title, this title will be used
		title: 'Promotion Group',
		// all titles will be injected into this template
		titleTemplate: '%s | Digital Business Card'
	},
	methods: {
		checkParams() {
			let params = window.location.search;
			let value = new URLSearchParams(params);
			console.log(window.location.id)
		},
		onResize() {
			let mode = localStorage.getItem('mode');
			this.windowWidth = window.innerWidth;
			if(this.windowWidth < 1200) {
				localStorage.setItem('mode', 'mobile');
				if(mode != 'mobile') {
					window.location.reload();
				}
			} else {
				localStorage.setItem('mode', 'desktop');
				if(mode != 'desktop') {
					window.location.reload();
				}
			}
		},
		init() {
			// this.$api.refreshToken().then(res => {
			// 	console.log('Refresh Token');
			// }).catch(err => {
			// 	console.error('ERR', err);
			// 	if(localStorage.getItem('tokentimer')) {
			// 		this.$router.push({ name: 'login.page' })
			// 	}
			// })
		},
	},
	mounted() {
		this.init();
		this.onResize();
		this.$nextTick(() => {
			window.addEventListener('resize', () => {
				this.onResize();
			});
		});


		this.checkParams();

	}
}
</script>
<style lang="scss">
.app__bg {
	position: fixed;
	top: 0;
	left: 0;
	display: block;
	@include size(100%,100vh);
	pointer-events: none;
	img {
		display: block;
		@include size(100%,100%);
		object-fit: cover;
		opacity: 0.2;
	}
}
.app__credits {
	@extend %flexCenterCenter;
	width: 100%;
	margin: 0 auto var(--gutter);
	font-size: 13px;
	img {
		display: block;
		@include size(41px,20px);
		margin-left: 10px;
	}
}
</style>
